import { BrowserRouter, Routes, Route } from "react-router-dom";
import  { Suspense, lazy } from 'react';
import Loader from "./components/loader/Loader";

// Lazy load components
const Home = lazy(() => import('./components/pages/Home/Home'));
const About = lazy(() => import("./components/pages/about/Aboutpage"));
const Marketplace = lazy(() => import('./components/pages/marketplace/Marketplace'));
const Minds = lazy(() => import('./components/pages/minds/Mind'));
const Work = lazy(() => import('./components/pages/work/Work'));
const MindDetails = lazy(() => import('./components/pages/minds_details/Mind_details'));
const SvDetails = lazy(() => import('./components/pages/serivces_details/Service'));


export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" >
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="marketplace" element={<Marketplace />} />
            <Route path="minds" element={<Minds />} />
            <Route path="work" element={<Work />} />
            <Route path="minds/:id" element={<MindDetails />} />
            <Route path="services/:id" element={<SvDetails />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
